<template>
  <v-container grid-list-md>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <h1>API</h1>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 md12>
        <v-card elevation="1">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="URL de la API"
                  v-model="operator.urlApi"
                  filled
                  clearable
                  hide-details="auto"
                  prepend-icon="mdi-api"
                  append-outer-icon="save"
                  @click:append-outer="saveOperator"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card elevation="1" class="mt-3">
      <v-simple-table dense>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Endpoints del API</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <div class="flex-grow-1"></div>
            <v-btn
              class="mr-2"
              color="primary"
              elevation="2"
              x-small
              v-show="visibleAdd"
              @click="add"
            >
              <v-icon small>mdi-plus</v-icon>Añadir
            </v-btn>
          </v-toolbar>
        </template>

        <template>
          <thead>
            <tr>
              <th>Método Web</th>
              <th>Uri</th>
              <th>Endpoint</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in operatorApi" :key="item.webMethodId">
              <td>{{ item.webMethod }}</td>
              <td>{{ item.uri }}</td>
              <td>{{ item.urlApi + item.uri }}</td>
              <td class="text-right">
                <v-btn outlined color="primary" elevation="2" x-small @click="edit(item)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!--EDITOR-->
    <v-dialog v-model="editorDialog" max-width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Endpoint </v-card-title>
        <v-form ref="form" lazy-validation @keyup.native.enter="submit">
          <v-container>
            <v-card-text>
              <v-container class="pb-0" fluid>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="operatorApiItem.webMethodId"
                      :items="avaliableWebMethods"
                      item-value="id"
                      item-text="name"
                      filled
                      hide-details="auto"
                      label="Método Web"
                      :readonly="editMode"
                      :rules="[v => !!v || 'El Método Web es obligatorio.']"
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Uri"
                      v-model="operatorApiItem.uri"
                      filled
                      clearable
                      hide-details="auto"
                      :rules="[v => !!v || 'La Uri es obligatoria.']"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-container>
        </v-form>

        <v-progress-linear :indeterminate="loading" color="primary" class="mb-0">
        </v-progress-linear>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="save"> Guardar </v-btn>
          <v-btn color="primary" text @click="editorDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Api",
  data: () => ({
    editorDialog: false,
    editMode: false,
    avaliableWebMethods: {},
    operatorApiItem: {},
  }),
  created() {
    this.$store.dispatch("fetchWebMethods");
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    operator() {
      return this.$store.getters.getOperator;
    },
    webMethods() {
      return this.$store.getters.getWebMethods;
    },
    operatorApi() {
      return this.$store.getters.getOperatorApi;
    },
    visibleAdd() {
      return this.webMethods?.length != this.operatorApi.length;
    },
  },
  methods: {
    add: function() {
      this.avaliableWebMethods = [];
      this.$store.dispatch("getAvaliableWebMethods").then(response => {
        this.avaliableWebMethods = response;
        this.operatorApiItem = {};
        this.editorDialog = true;
        this.editMode = false;
      });
    },
    edit: function(item) {
      this.avaliableWebMethods = this.webMethods;
      this.operatorApiItem = item;
      this.editorDialog = true;
      this.editMode = true;
    },
    save: function() {
      var form = this.$refs.form;
      if (form.validate()) {
        this.$store.dispatch("saveOperatorApi", this.operatorApiItem).then(
          () => {
            this.$store.dispatch("fetchOperatorApi");
            this.editorDialog = false;
          },
          () => {
            this.editorDialog = true;
          }
        );
      }
    },
    saveOperator: function() {
      this.$store.dispatch("saveOperator");
      this.$store.dispatch("fetchOperatorApi");
    },
  },
};
</script>

<style scoped></style>
